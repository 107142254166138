// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import fetch from 'cross-fetch';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import parse from 'autosuggest-highlight/parse';
import matches from 'autosuggest-highlight/match';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import LeadStatus from '@upsales/components/LeadStatus';
import { makeStyles } from '@material-ui/core/styles';




import CheckIcon from '@material-ui/icons/Check';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FlashOnIcon from '@material-ui/icons/FlashOn';

const useStyles = makeStyles((theme) => ({
textField: {
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',            
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: 500,
        background:'white',
        borderRadius:'5px'
    },
    input: {
        color: 'black'
    },
    spinner: {
      marginRight:'40px'
    }
}));

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const filterOptions = createFilterOptions( { stringify: ({ id, name }) => {
    //console.log(`returning with ${id} ${name}`)
    return `${id} ${name}`
} } );
export default function Asynchronous() {
  const url_params = new URLSearchParams(window.location.search);
  const token_param = url_params.get("token");  
  let timerID;
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [query, setQuery] = React.useState('');
  //const [token, setToken] = React.useState(token_param);

  console.log(token_param)
  React.useEffect(() => {
    console.log('running useEffect, query is',query)
    let active = true;
    
    if(!query) {
      setQuery("")
      return undefined
    }
    (async () => {
    setLoading(true)
      try {
        console.log('in here!')
        //const response_old = await fetch(`https://sandbox.upsales.com/api/v2/quicksearch?q={"a":"searchValue","c":"eq","v":"${query}"}&token=${token_param}&clientLimit=50`);
        const response = await fetch(`/api/test/?query=${query}`);
        let countries = await response.json();
        
        //console.log('data is', countries.data.client.data.map(client => client));
        let response2;
        let more;
        if(countries.data.client.data.length >=5) {
           //response2 = await fetch(`https://sandbox.upsales.com/api/v2/quicksearch?q={"a":"searchValue","c":"eq","v":"${query}"}&token=42f241bd1ae429e93b6e668cf4ba85fe4a1cd71781852c7ce3ec923449c2c068`);        
           //more = await response2.json();
        }
        more = undefined;
        if(more) countries.data.client.data = countries.data.client.data.concat(more.data.client.data)
        if (active) {
          setLoading(false)
          setOptions(countries.data.client.data.map(client => {
            //console.log('here is the client', client);

            let org_no = client.custom.find(cf => cf.fieldId === 7);
            org_no = org_no ? org_no.value : undefined;
              //const org_no = client.orgNo || '';
            let account_manager = client.users[0] ? client.users[0].name : ''
              //org_no = org_no ? org_no.value : undefined;

              return { full_client:client,id:org_no, name:client.name, account_manager, has_order:!!client.hasOrder, has_activity:!!client.hasActivity, has_opportunity:!!client.hasOpportunity }
          }))
        }        
      } catch (error){
        console.log('got an error', error)
      }

    })();

    return () => {
      active = false;
    };
  }, [query]);

  React.useEffect(() => {
    if (!open) {
      //setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      style={{ width: '45%' }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      filterOptions={filterOptions}
      //getOptionSelected={(option, value) => {
       // return option.name === value.name
      //}}
        getOptionLabel={({ id, name }) => {
          // this is how our option will be displayed when selected
          // remove the `id` here
          return `${name}`;
        }}
      options={options}
      clearOnBlur={false}
      onChange={(event, newValue) => {
        setOptions(options);
      }}      
      onInputChange={(event) => {
        clearTimeout(timerID)
        timerID = setTimeout(() => {
            //console.log('some action after delay')
            setQuery(event.target.value)
        }, 1000)        
        

      }}
      renderOption={(option) => {
        const parts_name = parse(
          `${option.name}`,
          [[   `${option.name.toLowerCase()}`.indexOf(query ? query.toLowerCase() : '') === -1 ? 0 : `${option.name.toLowerCase()}`.indexOf(query ? query.toLowerCase() : ''),  `${option.name.toLowerCase()}`.indexOf(query ? query.toLowerCase() : '') + (`${option.name.toLowerCase()}`.indexOf(query ? query.toLowerCase() : '') === -1 ? 1 : query ? query.length : 0)]]
        );  
        const parts_org = parse(
          `${option.id}`,
          [[   `${option.id}`.indexOf(query) === -1 ? 0 : `${option.id}`.indexOf(query),  `${option.id}`.indexOf(query) + (`${option.id}`.indexOf(query) === -1 ? 1:  query.length)]]
        );          
        return (

          <Grid container alignItems="center" justify="space-between" direction="row">
            <Grid item xs>
            
              <Typography variant="subtitle1" color="textPrimary">
                  {parts_name.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 900 : 400 }}>
                      {part.text}
                    </span>
                  ))}
              </Typography>
                

              <Typography variant="body2">
                    <LeadStatus account={option.full_client} size="small" market={false} />
              </Typography>

            <Typography variant="body2" color="textSecondary">
                  {`Hitta.se-ID (HID): `}
                  {parts_org.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 900 : 400 }}>
                      {part.text}
                    </span>
                  ))}
              </Typography>
            </Grid>
            <Grid item xs>
            <Typography variant="body2" color="textPrimary" align="right">
                Kontoansvarig
              </Typography>
              <Typography variant="body2" color="textSecondary" align="right">
                {option.account_manager || 'Ingen kontoansvarig'}
              </Typography>
            
            </Grid>  
          </Grid>
        );
      }}
      renderInput={(params) => (
        <TextField
          className={classes.textField}
          {...params}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            className: classes.input,
            endAdornment: (
              <React.Fragment>
                {loading && query ? <CircularProgress color="inherit" size={20}  className={classes.spinner}/> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
