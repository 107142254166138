import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Searchbar from './Searchbar';
import { makeStyles } from '@material-ui/core/styles';
import logo from './upsales_logo_light_green.png';
const useStyles = makeStyles((theme) => ({
  logo: {

  },
  grow: {
    overflow:'hidden',
    //'background':'linear-gradient(45deg,#002f65,#3367c0)!important',
    'background':'#1D3D48',
    height:'75px',
    'text-align':'center',
    position:'fixed',
    width:'100%',
    justifyContent:'left',
    alignItems:'left',
    display: 'flex',
    justifyContent:'left',
    alignItems:'center',
    border:"0px solid blue"
  },
  w: {
    width:'25%',
    justifyContent:'left',
    alignItems:'left',
    display: 'flex',
    justifyContent:'left',
    color:'white',
    marginLeft:'10px'
  },
img_scale: {
    width:'50%',
  }  


}));
export default function TopBar() {
  const classes = useStyles();
  return (
    <div className={classes.grow}>
        <div  className= {classes.w}>
          <img className= {classes.img_scale}src={logo}/>  
        </div>
        
        <Searchbar />
    </div>      
  )
}
